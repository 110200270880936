// REACT AND GATSBY
import React, { useState } from 'react';
// ELEMENTS AND COMPONENTS
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Button from 'reusecore/src/elements/Button';
import Input from 'reusecore/src/elements/Input';
import Container from 'common/src/components/UI/Container';
// STYLING
import NewsletterWrapper, { ContactFormWrapper, Styles } from './styles'; // CSS styling
// import NewsletterWrapper, { ContactFormWrapper } from './newsletter.style';
import PodcastSearch from 'common/src/components/PodcastSearch';
import PodcastTile from 'common/src/components/InfoTile/podcastTile';
// LOGIC AND HANDLERS
import { InputHandler } from './handlers'; // Code to handle user I/O
// NPM MODULES

const Newsletter = ({
  podcastSearch = false,
  titleText = 'Let your sound be found.',
  descText = "Sign up for our newsletter, and we'll transcribe one episode of your choice for FREE.",
  type = 'beta',
}) => {
  // Signup object react hook
  let [signupObj, updateSignup] = useState({
    type,
    identity: ' ',
  });
  let [podcast, updatePodcast] = useState({});
  const {
    row,
    buttonArea,
    buttonStyle,
    description,
    sectionWrapper,
    title,
  } = Styles;

  // const emptyFrag = <Fragment></Fragment>;

  return (
    <NewsletterWrapper {...sectionWrapper} id="newsletter_section">
      <Container>
        {/*** HEADING & COPY ***/}
        <Box {...row}>
          <Heading content={titleText} {...title} />
          <Text {...description} content={descText} />
        </Box>

        {/*** PODCAST SEARCH WIDGET ***/}
        {podcastSearch ? (
          <Box {...row}>
            <Heading content="Step 1: Find your podcast" {...title} />
            <PodcastSearch
              tileClickCB={(podcast) => {
                document.getElementById('subscribe').scrollIntoView();
                updatePodcast(podcast);
              }}
            />
          </Box>
        ) : (
          <></>
        )}

        {/*** SELECTED PODCAST ***/}
        {podcastSearch && podcast.image ? (
          <>
            <Box {...row}>
              <PodcastTile tileStyle="imageOnly" podcast={podcast} />
            </Box>
            <Box {...row}>
              <Text
                {...description}
                content={podcast.total_episodes + ' episodes'}
              />
            </Box>
          </>
        ) : (
          <></>
        )}

        {/*** EMAIL FORM INPUT ***/}
        <Box id="subscribe" {...row}>
          {podcastSearch ? (
            <>
              <Heading content="Step 2: Enter your email" {...title} />
            </>
          ) : (
            <></>
          )}

          <Box {...buttonArea}>
            <ContactFormWrapper>
              <Input
                inputType="email"
                label="Enter your email"
                iconPosition="right"
                isMaterial={true}
                className="email_input"
                arial-label="email"
                // onKeyDown={e => handleEnterKey(e)}
                onKeyDown={(e) =>
                  InputHandler.handleEnterKey(e, async () => {
                    await InputHandler.betaSignup(signupObj);
                  })
                }
                onChange={(email) =>
                  updateSignup({
                    identity: email,
                    type,
                    podcast,
                  })
                }
              />
              <Button
                {...buttonStyle}
                title="SUBSCRIBE"
                onClick={() => InputHandler.betaSignup(signupObj)}
              />
            </ContactFormWrapper>
          </Box>
        </Box>
      </Container>
    </NewsletterWrapper>
  );
};

export default Newsletter;
