/**
 * Handler for input events for Newsletter
 *
 * This is where we call our custom API to manage Job requests
 */
import { doFetch as podstacksAPI } from '../../../../../api/PodstacksAPI';
import { handleEnterKey } from '../../../../../api/BrowserAPI';
import fetch from 'cross-fetch';
const nanoid = require('nanoid');

// Stores email in the database using an API
const betaSignup = async (signupObj) => {
  console.log('Creating beta signup: ' + signupObj.identity);
  const tracking = await fetch('https://ipapi.co/json/'); // SHHH Don't tell anyone!

  // Simplified API fetch
  //const doFetch = async ( apiName, apiEndpoint, params = null, query = null, body = null, cb = null, errCb = null )
  const res = await podstacksAPI(
    'users',
    'createUser',
    null,
    null,
    {
      user: {
        userId: signupObj.podcast.id ? signupObj.podcast.id : nanoid(8), // userId is ListenNotes API podcast ID
        customerId: '', // Stripe customer id
        type: signupObj.type,
        billing: {}, // (OBJ) Billing info, encryption somehow?
        identity: signupObj.identity, // (OBJ) Usernames, real names, contact info, emails
        jobs: [], // (OBJ) Metadata on user Jobs
        login: {}, // (OBJ) Login object, contains tokens
        notify: {}, // (OBJ) Notifications and messaging object
        podcast: signupObj.podcast ? signupObj.podcast : {}, // (OBJ) Podcast data
        status: {}, // (OBJ) User status object
        tracking: await tracking.json(), // (OBJ) Tracking object
        urls: {}, // (OBJ) Urls object
      },
    },
    null,
    null
  );

  if (res) {
    alert('Thanks ' + signupObj.identity + '! We will be in touch soon.');
  } else {
    alert('ERROR!');
  }
};

export const InputHandler = {
  betaSignup,
  handleEnterKey,
};
