/**
 * Podstacks API Module
 *
 * This acts as the main entry point for the UI to interact with the Podstacks
 * API module.  It includes an API REQUEST MAP and functions to simplify
 * building, executing, and returning values from HTTPS API requests
 *
 */
import fetch from 'cross-fetch';
import queryString from 'query-string';

// API REQUEST MAP
// You can copy the endpoint URIs from the serverless output (see above) after you run deploy
const api = {
  reqHeaders: {
    'Content-Type': 'application/json',
  },
  // AUTH API
  auth: {
    getMagicLink: {
      endpoint:
        'https://qrebdc82fl.execute-api.us-east-1.amazonaws.com/dev/auth/getMagicLink',
      fetchParams: { mode: 'no-cors', method: 'GET' },
    },
    validateToken: {
      endpoint:
        'https://qrebdc82fl.execute-api.us-east-1.amazonaws.com/dev/auth/validateToken',
      fetchParams: { mode: 'no-cors', method: 'GET' },
    },
  },

  // AWST API
  awst: {
    loadAudio: {
      endpoint:
        'https://54eh0d5ud7.execute-api.us-east-1.amazonaws.com/dev/awst/loadAudio/{userId}/{jobId}',
      fetchParams: { mode: 'no-cors', method: 'POST' },
    },
    deleteAudio: {
      endpoint:
        'https://54eh0d5ud7.execute-api.us-east-1.amazonaws.com/dev/awst/deleteAudio/{userId}/{jobId}',
      fetchParams: { mode: 'no-cors', method: 'DELETE' },
    },
    start: {
      endpoint:
        'https://54eh0d5ud7.execute-api.us-east-1.amazonaws.com/dev/awst/start/{userId}/{jobId}',
      fetchParams: { mode: 'no-cors', method: 'POST' },
    },
    getStatus: {
      endpoint:
        'https://54eh0d5ud7.execute-api.us-east-1.amazonaws.com/dev/awst/getStatus/{userId}/{jobId}',
      fetchParams: { mode: 'no-cors', method: 'GET' },
    },
    saveRaw: {
      endpoint:
        'https://54eh0d5ud7.execute-api.us-east-1.amazonaws.com/dev/awst/saveRaw/{userId}/{jobId}',
      fetchParams: { mode: 'no-cors', method: 'POST' },
    },
  },

  // EXPORT API (Note: 'exports' used due to reserved keyword)
  exports: {
    preExport: {
      endpoint:
        'https://p2q5r383yb.execute-api.us-east-1.amazonaws.com/dev/export/preExport/{userId}/{jobId}',
      fetchParams: { mode: 'no-cors', method: 'POST' },
    },
    toMarkdown: {
      endpoint:
        'https://p2q5r383yb.execute-api.us-east-1.amazonaws.com/dev/export/toMarkdown/{userId}/{jobId}',
      fetchParams: { mode: 'no-cors', method: 'POST' },
    },
  },

  // JOBS API
  jobs: {
    // id:     'f3fmzmva8d',
    // stage:  'dev',
    createJob: {
      endpoint:
        'https://f3fmzmva8d.execute-api.us-east-1.amazonaws.com/dev/jobs/create',
      fetchParams: { mode: 'no-cors', method: 'POST' },
    },
    getJob: {
      endpoint:
        'https://f3fmzmva8d.execute-api.us-east-1.amazonaws.com/dev/jobs/{userId}/{jobId}',
      fetchParams: { mode: 'no-cors', method: 'GET' },
    },
    getUserJobs: {
      endpoint:
        'https://f3fmzmva8d.execute-api.us-east-1.amazonaws.com/dev/jobs/user/{userId}',
      fetchParams: { mode: 'no-cors', method: 'GET' },
    },
    updateJob: {
      endpoint:
        'https://f3fmzmva8d.execute-api.us-east-1.amazonaws.com/dev/jobs/{userId}/{jobId}',
      fetchParams: { mode: 'no-cors', method: 'PUT' },
    },
    deleteJob: {
      endpoint:
        'https://f3fmzmva8d.execute-api.us-east-1.amazonaws.com/dev/jobs/{userId}/{jobId}',
      // endpoint:      'http://54.91.65.124:3000/dev/jobs/{userId}/{jobId}',
      // endpoint:      'http://0.0.0.0:3000/jobs/{userId}/{jobId}',
      fetchParams: { mode: 'no-cors', method: 'DELETE' },
    },
    deleteS3: {
      endpoint:
        'https://f3fmzmva8d.execute-api.us-east-1.amazonaws.com/dev/jobs/s3/{userId}/{jobId}',
      fetchParams: { mode: 'no-cors', method: 'DELETE' },
    },
  },

  // PODCASTS API
  podcasts: {
    searchPodcasts: {
      endpoint:
        'https://0hl3744n88.execute-api.us-east-1.amazonaws.com/dev/podcasts/search',
      fetchParams: { mode: 'no-cors', method: 'GET' },
    },
    getPodcastEpisodes: {
      endpoint:
        'https://0hl3744n88.execute-api.us-east-1.amazonaws.com/dev/podcasts/{podcast_id}',
      fetchParams: { mode: 'no-cors', method: 'GET' },
    },
    getEpisodeData: {
      endpoint:
        'https://0hl3744n88.execute-api.us-east-1.amazonaws.com/dev/podcasts/episode/{episode_id}',
      fetchParams: { mode: 'no-cors', method: 'GET' },
    },
    audioSupport: {
      endpoint:
        'https://0hl3744n88.execute-api.us-east-1.amazonaws.com/dev/podcasts/episode/audioSupport/{episode_id}',
      fetchParams: { mode: 'no-cors', method: 'GET' },
    },
  },

  // PURCHASES API
  purchases: {
    getPricing: {
      endpoint:
        'https://fyst0hfdx8.execute-api.us-east-1.amazonaws.com/dev/purchases/getPricing',
      fetchParams: { mode: 'no-cors', method: 'GET' },
    },
    processPayment: {
      endpoint:
        'https://fyst0hfdx8.execute-api.us-east-1.amazonaws.com/dev/purchases/processPayment',
      fetchParams: { mode: 'no-cors', method: 'POST' },
    },
  },

  // USERS API
  users: {
    createUser: {
      endpoint:
        'https://qawnbb4q5c.execute-api.us-east-1.amazonaws.com/dev/users/create',
      fetchParams: { mode: 'no-cors', method: 'POST' },
    },
    getUser: {
      endpoint:
        'https://qawnbb4q5c.execute-api.us-east-1.amazonaws.com/dev/users/{userId}',
      fetchParams: { mode: 'no-cors', method: 'GET' },
    },
    getUsers: {
      endpoint:
        'https://qawnbb4q5c.execute-api.us-east-1.amazonaws.com/dev/users/type/{type}',
      fetchParams: { mode: 'no-cors', method: 'GET' },
    },
    filterUsers: {
      endpoint:
        'https://qawnbb4q5c.execute-api.us-east-1.amazonaws.com/dev/users/filter',
      fetchParams: { mode: 'no-cors', method: 'POST' },
    },
    updateUser: {
      endpoint:
        'https://qawnbb4q5c.execute-api.us-east-1.amazonaws.com/dev/users/{userId}',
      fetchParams: { mode: 'no-cors', method: 'PUT' },
    },
    deleteUser: {
      endpoint:
        'https://qawnbb4q5c.execute-api.us-east-1.amazonaws.com/dev/users/{userId}',
      fetchParams: { mode: 'no-cors', method: 'DELETE' },
    },
    deleteS3: {
      endpoint:
        'https://qawnbb4q5c.execute-api.us-east-1.amazonaws.com/dev/users/s3/{userId}',
      fetchParams: { mode: 'no-cors', method: 'DELETE' },
    },
  },
};

// Builds fetch parameters
const buildFetch = (
  apiName,
  apiEndpoint,
  params = null,
  query = null,
  body = null
) => {
  let uri = api[apiName][apiEndpoint].endpoint;
  // Adds parameters using regex search and replace as needed
  // Currently only works with required parameters for now...
  // console.log(params)
  if (params) {
    const paramKeys = Object.keys(params);
    paramKeys.map((param) => {
      uri = uri.replace(new RegExp('({' + param + '})'), params[param]);
    });
  }
  // Adds query parameters to uri
  if (query) {
    const qs = queryString.stringify(query);
    uri += '?' + qs;
  }
  // Build fetch options and add body
  let options = api[apiName][apiEndpoint].fetchParams;
  options.header = api.reqHeaders;
  if (body) options.body = JSON.stringify(body);

  console.log({ uri, options });
  return { uri, options };
};

// Generic fetch
const doFetch = async (
  apiName,
  apiEndpoint,
  params = null,
  query = null,
  body = null,
  cb = null,
  errCb = null
) => {
  // Generic debug entry point message
  console.log('Executing ' + apiEndpoint + '...');
  const fetchParams = buildFetch(apiName, apiEndpoint, params, query, body);
  // console.log(fetchParams)
  return fetch(fetchParams.uri, fetchParams.options)
    .then(async (res) => {
      const epData = await res.json();
      return epData.message;
    })
    .catch((error) => {
      // Generic error message
      // alert(api.apiName.apiEndpoint.defaultErr +': '+ error);        // Browser target only
      console.log(apiEndpoint + ' error: ' + error); // Node target only
    });
};

const test = async () => {
  // console.log( await doFetch( 'jobs', 'createJob', null, {
  //   job: {
  //     jobId         : 'some-other-job',
  //     userId        : 'this-is-tim-ferriss',
  //     status        : 'changed this',
  //     customDict    : 'i poop my pants',
  //     speakerNames  : [ 'Tai', 'Tim', 'Joe' ],
  //     exportFormats : [ 'md', 'txt' ],
  //     urls          : { someurl: 'myurl', notherurl: 'hurl', nested: {works: 'test'} },
  //     audioSupport  : { someaudio: 'support', object: 'ok'},
  //     awstJob       : { awst: 'job' }
  //   }
  // } ))
  // console.log( await doFetch( 'jobs', 'getJob', { userId: 'this-is-tim-ferriss', jobId: 'someid' }))
  // console.log( await doFetch( 'jobs', 'updateJob', null, { job: { userId: 'this-is-tim-ferriss', jobId: 'someid', urls: {} }} ))
  // console.log( await doFetch( 'jobs', 'getUserJobs', { userId: 'this-is-tim-ferriss', jobId: 'someid' }))
  // console.log( await doFetch( 'jobs', 'deleteJob', { userId: 'this-is-tim-ferriss', jobId: 'some-other-job' }))
};
// test()

// const podstacksAPI = doFetch
// export default { podstacksAPI: doFetch, getUrlParams }

export { doFetch };
